import Link from 'next/link'
import { ComponentProps, type ForwardedRef, forwardRef } from 'react'
import { TextLink as TextLinkComponent } from '@betterplace/design-system/server'
type TextLinkProps = Omit<ComponentProps<typeof TextLinkComponent>, 'href'> & Pick<ComponentProps<typeof Link>, 'href'>
const TextLink = forwardRef(function TextLink(
  { href, children, ...props }: TextLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return (
    <Link href={href} passHref legacyBehavior>
      <TextLinkComponent ref={ref} data-axe-expect="color-contrast" {...props}>
        {children}
      </TextLinkComponent>
    </Link>
  )
})

export default TextLink

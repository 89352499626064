'use client'
import { GoogleTagManager } from '@next/third-parties/google'

import LogoCompact from '@/components/LogoCompact'
import React, { type ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import styles from './CookieBanner.module.css'
// eslint-disable-next-line consistent-default-export-name/default-import-match-filename
import Hotjar from './Hotjar'
import I18nHtml from '@/components/I18nHtml'
import Modal from '@/donationForm/_dependencies/components/shared/Modal'
import useAnalytics from './useAnalytics'
import useAutoReject from './useAutoReject'
import useTrackingCookie from './useTrackingCookie'
import { Button, ButtonProps, Heading, IconButton } from '@betterplace/design-system/client'
import { ClientCookieBannerProps } from './types'
import { useTranslations } from 'next-intl'

function RejectButton({ onClick, children, ...buttonProps }: ButtonProps<'button'>) {
  return (
    <Button onClick={onClick} {...buttonProps} kind="secondary" ghost>
      {children}
    </Button>
  )
}

const AcceptButton = forwardRef(function AcceptButton(
  { onClick, children, ...props }: ButtonProps<'button'>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <IconButton {...props} onClick={onClick} iconName="check" ref={ref}>
      {children}
    </IconButton>
  )
})
function ClientCookieBanner({
  domain,
  environment,
  serverSideCookieValue,
  gtmAuth,
  gtmId,
  gtmPreview,
  hotjarId,
}: ClientCookieBannerProps) {
  const [trackingCookieValue, setTrackingCookie] = useTrackingCookie(serverSideCookieValue)
  const [showCookiesModal, setShowCookiesModal] = useState(false)
  const focusRef = useRef<HTMLButtonElement>(null)
  const t = useTranslations('nextjs')

  const acceptTracking = useCallback(() => {
    setTrackingCookie('accepted', domain)
  }, [domain, setTrackingCookie])

  const rejectTracking = useCallback(() => {
    setTrackingCookie('rejected', domain)
  }, [domain, setTrackingCookie])

  const cookieBannerRef = useAutoReject(rejectTracking, false, trackingCookieValue)

  const openModal = useCallback(() => {
    setShowCookiesModal(true)
  }, [setShowCookiesModal])

  // if we do a/b test, we would need to pass environment as a parameter to this function
  // then we need to define what we want to do inside based on the environment
  // ( whether to run Posthog/analytics/etc.)
  useAnalytics(trackingCookieValue)

  useEffect(() => {
    if (!focusRef.current) return
    focusRef.current.focus()
  }, [])

  if (environment === 'test') {
    return null
  }

  if (trackingCookieValue === 'rejected') {
    return null
  }

  if (trackingCookieValue === 'accepted') {
    const gtmEnabled = gtmAuth && gtmId && gtmPreview
    // GTM & Hotjar
    return (
      <>
        {!!gtmEnabled && <GoogleTagManager gtmId={gtmId} auth={gtmAuth} preview={gtmPreview} />}
        <Hotjar hotjarId={hotjarId} />
      </>
    )
  }

  return (
    <div id="cookie-banner" ref={cookieBannerRef} className={styles.cookieBannerContainer}>
      <div className={styles.cookieContentContainer}>
        <Modal
          isOpen={showCookiesModal}
          onClose={() => setShowCookiesModal(false)}
          title={t('cookie_banner.information_headline')}
          hideActions
          closeText={t('cookie_banner.close_button_label')}
          fullWidth
        >
          <I18nHtml
            i18nKey="nextjs.cookie_banner.information_details_html"
            options={{
              href: t('cookie_banner.information_details_url'),
              target: '_blank',
            }}
            className={styles.informationDetailsContent}
          />
        </Modal>

        <div className={styles.row}>
          <div className={styles.logoWrapper}>
            <div className={styles.logo} style={{ height: '100%' }}>
              <LogoCompact style={{ width: 135 }} />
            </div>
          </div>
          <div className={styles.textContent}>
            <Heading as="h2" level="h100">
              {t('cookie_banner.headline')}
            </Heading>
            <p>{t('cookie_banner.introduction_text')}</p>
            <p>{t('cookie_banner.call_for_action')}</p>
            <div className={styles.cookieBannerButtonRow}>
              <div className={styles.moreInformationWrapper}>
                {/* NOTE: This looks different from the one we have on the old banner */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Button as="a" href="javascript:void(0)" onClick={openModal} kind="plain" ghost size="small">
                  {t('cookie_banner.information_button')}
                </Button>
              </div>
              <div className={styles.cookieActionButtonsContainer}>
                <RejectButton onClick={rejectTracking}>{t('cookie_banner.reject_button')}</RejectButton>
                <AcceptButton onClick={acceptTracking} ref={focusRef} className={styles.acceptButton}>
                  {t('cookie_banner.accept_button')}
                </AcceptButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientCookieBanner
